.sidebarAd {
    z-index: 1;
    position: fixed;
    top: 89px;
    width: calc(((100vw - 1200px) / 2) - 32px);
    box-sizing: border-box;
}

.sidebarAd.left {
    left: 16px;
}

.sidebarAd.right {
    right: 16px;
}

.sidebarAd .ad {
    
}